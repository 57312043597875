//local
// const BASE_URL = "http://192.168.21.19:5000/node-api";
// const DEFAULT_KEY = '88e97c4a-c588-4472-829c-f9f43fe0743a'

// demo
const BASE_URL = "https://admin.jay.iossmlm.com/node-api/";
const DEFAULT_KEY = '88e97c4a-c588-4472-829c-f9f43fe0743a'

const PAYPAL_CLIENT_ID = "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75"

export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID }
